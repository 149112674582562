import logo from './logo.svg';
import './App.css';
import WithNode from './Pages/WithNode';

function App() {
  return (
    <div className="App">
     <WithNode/>
    </div>
  );
}

export default App;
