import React from 'react'

const WithNode = () => {
  return (
    <div className="App">
      <div>Header</div>
      <img src='https://images.ctfassets.net/aq13lwl6616q/7cS8gBoWulxkWNWEm0FspJ/c7eb42dd82e27279307f8b9fc9b136fa/nodejs_cover_photo_smaller_size.png' alt='image' />
    </div>
  );
}

export default WithNode